body {
  background: #16181d;
  color: #ffffff;
  font-size: 16px;
}
a {
  color: #ffffff !important;
  text-decoration: none;
  transition: 0.5s;
}
a:hover {
  color: #ffba00 !important;
  text-decoration: none;
}
.selectCountry {
  padding: 0px;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

#google_translate_element {
  position: static;
  bottom: calc(53px + 16px);
  right: 16px !important;
}
.goog-te-gadget {
  font-size: 0 !important;
}
.goog-te-gadget-simple {
  background-color: #210a44 !important;
  border: 0px solid rgba(255, 255, 255, 0.5) !important;
  padding: 5px 10px !important;
  border-radius: 0px !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  display: inline-block;
  cursor: pointer;
  zoom: 1;
}
.goog-te-menu-value {
  color: #fff !important;
}

.goog-te-menu-value span:nth-child(5) {
  display: none;
}
.goog-te-menu-value span:nth-child(3) {
  border: none !important;
}
.goog-te-menu-value span:nth-child(3):after {
  font-family: "Font Awesome 5 Free";
  content: "\f0d7";
  font-weight: 700;
  color: #ffba00;
  margin-left: 4px;
}
.goog-te-gadget-icon {
  background-image: url(https://placehold.it/32) !important;
  background-position: 0px 0px;
  height: 32px !important;
  width: 32px !important;
  margin-right: 8px !important;
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
.goog-logo-link {
  display: none;
}
body {
  top: 0px !important;
}
.flag-select__options {
  width: 100%;
  background: #210a44 !important;
  border: 0 !important;
}
.flag-select__option:not(.flag-select__option--placeholder):hover,
.flag-select__option:not(.flag-select__option--placeholder):focus {
  background: #45108a !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.input-group-append {
  padding-top: 6%;
  padding-right: 3%;
  text-align: center;
}
.input-group i {
  position: absolute;
}
.flag-select__btn {
  background: #210a44 !important;
  outline: none !important;
  border-radius: 5px;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.flag-select__option__label {
  display: none;
}
.flag-select__option__icon {
  top: 0 !important;
  width: 18px !important;
  height: 18px !important;
}
.flag-select__btn::after {
  border-top-color: #ffba00 !important;
}
.css-yk16xz-control,
.css-1h2k51u-control {
  background: #16181d !important;
  border-color: #42444b !important;
  color: #fff !important;
}
.css-tlfecz-indicatorContainer {
  color: #42444b !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: #42444b !important;
}
.css-yk16xz-control:focus {
  background: transparent !important;
}
.css-26l3qy-menu div {
  color: #000 !important;
}
.css-1uccc91-singleValue {
  color: inherit !important;
  background: transparent !important;
}
.container {
  max-width: 1366px;
}
.btn {
  box-shadow: none !important;
}
.homeHeader {
  background: url(../images/landingTopCurve.jpg) no-repeat scroll center -290px;
  background-size: cover;
}
.pageHeader {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#45108a+0,3a065e+33,250556+100 */
  background: #45108a; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #45108a 0%,
    #3a065e 33%,
    #250556 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #45108a 0%,
    #3a065e 33%,
    #250556 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #45108a 0%,
    #3a065e 33%,
    #250556 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45108a', endColorstr='#250556',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.homebanner {
  margin-top: 15px;
}
.homebanner h2 {
  font-size: 54px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}
.abtBanner {
  background: url(../images/aboutBanner.jpg) no-repeat scroll center center;
  background-size: cover;
  min-height: 147px;
}
.appformBanner {
  background: url(../images/appForm.jpg) no-repeat scroll center center;
  background-size: cover;
  min-height: 147px;
}
.careerBanner {
  background: url(../images/career.jpg) no-repeat scroll center center;
  background-size: cover;
  min-height: 147px;
}
.pageHeader .homebanner h2 {
  font-size: 54px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 30px;
}
.pageHeader .homebanner h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: #ffba00;
}
.pageHeader .homebanner h5 span {
  display: inline-block;
}
.pageHeader .homebanner h5 span::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #ffba00;
  display: inline-block;
}
.homebanner h6 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #b0b0b0;
}
.customNav .navbar-nav {
  align-items: center;
}
.customNav .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 15px;
  padding: 0;
}
.btnType1 {
  background: transparent;
  padding: 6px 30px !important;
  border: 1px solid #ffba00;
}
.btnType1 font {
  vertical-align: initial !important;
}
.homeIconBox {
  margin-bottom: 60px;
  margin-top: 15px;
}
.iconBox1 {
  background-color: #1b1f2f;
  border-top: 2px solid #4b5ecf;
  padding: 30px;
  text-align: justify;
  min-height: 270px;
}
.iconBox2 {
  background-color: #2e291a;
  border-top: 2px solid #ffba00;
  padding: 30px;
  text-align: justify;
  min-height: 270px;
}
.iconBox3 {
  background-color: #251b31;
  border-top: 2px solid #a932df;
  padding: 30px;
  text-align: justify;
  min-height: 270px;
}
#heroLandingSlider {
  background-color: #ffba00;
  padding: 40px;
}
.carouselText p {
  color: #000000;
}
.homeSlider {
  margin-bottom: 40px;
}
#heroLandingSlider .carousel-control-prev {
  background: url("../images/sliderHomeArrow.png") -10px -10px;
  width: 27px;
  height: 19px;
  border: 0;
  outline: none;
  box-shadow: none;
  opacity: 1;
  position: absolute;
  display: inline-flex;
  bottom: 40px;
  top: auto;
  right: 80px;
  left: auto;
}
#heroLandingSlider .carousel-control-prev:hover {
  background-position: -57px -10px;
}
#heroLandingSlider .carousel-control-next {
  background: url("../images/sliderHomeArrow.png") -57px -49px;
  width: 27px;
  height: 19px;
  border: 0;
  outline: none;
  box-shadow: none;
  opacity: 1;
  position: absolute;
  display: inline-flex;
  margin-left: 15px;
  bottom: 40px;
  top: auto;
  right: 40px;
  left: auto;
}
#heroLandingSlider .carousel-control-next:hover {
  background-position: -10px -49px;
}
.customerSay {
  background: url(../images/worlmap.png) no-repeat scroll center center;
  min-height: 366px;
  padding-bottom: 15px;
}
.customerCard {
  margin-top: 40px;
}
.customerCard h4 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
}
.customerCard p {
  text-align: center;
}
.customerCard p span {
  color: #ffba00;
  display: block;
}
.homeDoubleLeft {
  background: #450f88 url(../images/coinbg.jpg) no-repeat scroll right top;
  padding: 50px 50px 30px 50px;
  background-size: cover;
}
.hdlContent {
  max-width: 600px;
  float: right;
}
.homeDoubleRight {
  background: #201c2d url(../images/righbg.jpg) repeat-x scroll 0 0;
  background-size: cover;
  height: 518px;
  position: relative;
  padding: 15px;
  overflow-y: auto;
}
.homeDoubleRight p {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  position: absolute;
  max-width: 595px;
  left: 35px;
  bottom: 20px;
  margin-bottom: 0;
}
.homeDoubleRight h5 {
}
/* Custom Scrollbar */
.homeDoubleRight::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.homeDoubleRight::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
.homeDoubleRight::-webkit-scrollbar-thumb {
  background-color: #ffba00;
}
.mainFooter {
  padding: 50px 0 35px;
}
.footerSocialLinks ul li {
  font-size: 30px;
  display: inline-block;
  margin-right: 25px;
}
.footerSocialLinks ul li a.facebookIconLink:hover {
  color: #3d5b9b;
}
.footerSocialLinks ul li a.twitterIconLink:hover {
  color: #02aaec;
}
.footerSocialLinks ul li a.whatsappIconLink:hover {
  color: #21b933;
}
.footerSocialLinks ul li a.linkedinIconLink:hover {
  color: #157396;
}
.footerLinks h6 {
  text-transform: uppercase;
  color: #a9a9a9;
  font-size: 16px;
}
.footerLinks p a {
  margin: 0 5px;
}
.footerLinks p a:first-child {
  margin-left: 0;
}
.footerLinks p small {
  font-size: 14px;
  color: #a9a9a9;
}
.aboutCoin {
  padding: 75px 0;
}
.aboutCoinContent {
  padding-top: 30px;
}
.aboutCoinContent p span {
  font-weight: 600;
  color: #ffba00;
}
.aboutDoubleImg {
  background: #1e2128;
  margin-right: 15px;
}
.adlContent {
  max-width: 600px;
  float: right;
  width: 100%;
  padding: 30px 0;
}
.aboutDoubleText {
  width: 100%;
  max-width: 650px;
  padding-left: 15px;
}
.alternateDouble .adlContent {
  max-width: 600px;
  float: right;
  width: 100%;
  padding: 20px 0;
  margin-right: 30px;
}
.alternateDouble {
  padding: 50px 0;
}
.bgColorDarkPurple {
  height: 10px;
  background: #450f88;
  width: 100%;
}
.bgColorLightPurple {
  height: 10px;
  background: #3b389d;
  width: 100%;
}
.adlRightImg {
  width: 100%;
  max-width: 600px;
}
.appForm {
  padding: 45px 0;
}
.appForm h3 {
  font-size: 24px;
  margin: 0;
  padding: 15px 0 15px 0;
  color: #ffba00;
}
.appForm input[type="text"],
.appForm input[type="email"],
.appForm input[type="number"],
.appForm input[type="password"],
.appForm textarea {
  padding: 8px 15px;
  box-shadow: none !important;
  background-color: #16181d !important;
  border: 1px solid #42444b;
  resize: none;
  color: #ffffff !important;
  font-size: 16px;
}
.appForm input[type="text"]:focus,
.appForm input[type="email"]:focus,
.appForm input[type="number"]:focus,
.appForm input[type="password"]:focus,
.appForm textarea:focus {
  border: 1px solid #ffba00;
}
.appForm .form-group,
.appForm .input-group {
  margin-bottom: 25px;
}
.appForm .form-group label,
.appForm .input-group label {
  display: block;
  width: 100%;
}
.appForm label sup {
  color: #ffba00;
}
.appForm .form-group .niceStyleSelect {
  width: 100%;
  box-shadow: none !important;
  background-color: #16181d;
  border: 1px solid #42444b;
  resize: none;
}
.appForm .form-group .niceStyleSelect.nice-select.open .list {
  width: 100%;
}
.appForm .form-group .niceStyleSelect.nice-select .option {
  color: #16181d;
}
.appForm .form-group .niceStyleSelect.nice-select .option:hover,
.appForm .form-group .niceStyleSelect.nice-select .option.focus,
.appForm .form-group .niceStyleSelect.nice-select .option.selected.focus {
  background-color: #ffba00;
}
.appForm .form-group .niceStyleSelect.nice-select::after {
  border-color: #ffba00;
}
.btnTypeSubmit {
  color: #ffffff;
  background: #ffba00;
  box-shadow: none !important;
  padding: 10px 30px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s;
  border: 2px solid #ffba00;
  cursor: pointer;
}
.btnTypeSubmit:hover {
  background: transparent;
  color: #ffba00;
}
.appForm .datePickerMain {
  width: 100%;
  position: relative;
}
.appForm .input-group-append {
  margin-left: -1px;
  position: absolute;
  top: 13px;
  right: 12px;
  color: #42444b;
}
.datepicker td,
.datepicker th {
  font-size: 14px;
}
.datepicker table tr td.highlighted {
  background: #fff2cf;
}
.postContent {
  padding: 50px 0;
}
.postText h4 {
  font-size: 24px;
  color: #ffba00;
  font-weight: 400;
}
.postText ul li {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.postText ul {
  margin-bottom: 20px;
}
.postText ul li i {
  font-size: 6px;
  color: #ffba00;
  margin-right: 10px;
}
.postText p {
  margin-bottom: 20px;
}
.postText p a {
  color: #ffba00;
  text-decoration: underline;
}
.postText p a:hover {
  color: #ffffff;
  text-decoration: none;
}
.team_single img {
  display: block;
  border-radius: 50%;
  width: 155px;
  height: 155px;
  margin: 0 auto 10px;
  border: 3px solid #ffba00;
  object-fit: cover;
}
.team_single_content {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  text-align: center;
  min-height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.team_single_content h4 {
  font-size: 18px;
}
.team_single_content h5 {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 400;
  color: rgb(255 255 255 / 75%);
}
.team_single {
  margin-bottom: 30px;
}

@media screen and (max-width: 1349px) {
  .adlContent {
    max-width: 500px;
  }
  .aboutDoubleText {
    max-width: 590px;
  }
  .alternateDouble .adlContent {
    max-width: 525px;
  }
  .adlRightImg {
    max-width: 500px;
  }
  .homeDoubleRight {
    height: 565px;
  }
}
@media screen and (max-width: 1279px) {
  .homeDoubleRight {
    height: 590px;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    max-width: 96%;
  }
  .customNav {
    position: static;
  }
  .customNav .navbar-toggler {
    background: transparent;
    outline: none;
    font-size: 24px;
    transition: 0.5s;
    border: 2px solid #ffbb03;
  }
  .customNav .navbar-toggler:hover {
    background: #ffbb03;
    border: 2px solid #ffbb03;
  }
  .customNav .navbar-toggler i {
    color: #ffbb03;
  }
  .customNav .navbar-toggler:hover i {
    color: #ffffff;
  }
  .customNav .navbar-collapse {
    position: absolute;
    width: 100%;
    background: #16181d;
    z-index: 9;
    padding-bottom: 15px;
    padding-top: 15px;
    top: 100px;
    left: 0;
  }
  .customNav .navbar-nav li {
    margin: 10px 0;
  }
  .flagToggler {
    display: flex !important;
    align-items: center;
  }
  .iconBox2,
  .iconBox1,
  .iconBox3 {
    min-height: 340px;
  }
  .hdlContent {
    max-width: 100%;
    float: none;
  }
  .homeDoubleRight p {
    max-width: 395px;
    left: 55%;
    bottom: 20%;
  }
  .adlContent {
    max-width: 100%;
  }
  .aboutDoubleText {
    max-width: 96%;
    padding-left: 0;
    margin: 20px auto 0;
  }
  .alternateDouble .adlContent {
    max-width: 96%;
    margin: 0 auto;
    float: none;
  }
  .adlRightImg {
    max-width: 100%;
  }
  .alternateDouble {
    padding: 30px 0;
  }
  .alternateDouble .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutDoubleImg {
    margin-right: 0;
  }
  .homeDoubleRight {
	height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .homeHeader {
    background-position: center -220px;
  }
  .homebanner h2 {
    font-size: 36px;
  }
  .pageHeader .homebanner h2 {
    font-size: 36px;
  }
  .homebanner h6 {
    font-size: 18px;
  }
  .iconBox2,
  .iconBox1,
  .iconBox3 {
    min-height: auto;
    margin-bottom: 35px;
  }
  .iconMainBox p {
    margin-bottom: 0;
  }
  .homeIconBox {
    margin-bottom: 0px;
  }
  .homeDoubleRight p {
    max-width: 320px;
    bottom: 20%;
  }
  .hdlContent {
    max-width: 100%;
  }
  .aboutCoin {
    padding: 50px 0 30px;
  }
  .aboutDoubleText {
    padding-left: 15px;
    padding-right: 15px;
  }
  .alternateDouble .adlContent {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .homeHeader {
    background-position: center -160px;
  }
  .homeDoubleRight p {
    max-width: 96%;
    bottom: 2%;
    left: 2%;
  }
  .homeDoubleLeft {
    padding: 20px;
  }
  .footerLogo .text-right {
    text-align: center !important;
  }
  .footerLogo {
    margin-bottom: 30px;
  }
  .aboutDoubleText {
    padding-left: 15px;
    padding-right: 15px;
  }
  .alternateDouble .adlContent {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 575px) {
  body {
    font-size: 14px;
  }
  .homeHeader {
    background-position: center -80px;
  }
  .homebanner h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .pageHeader .homebanner h2 {
    font-size: 28px;
    margin-bottom: 0px;
  }
  .customerCard h4 {
    font-size: 18px;
  }
  .footerLinks p small {
    font-size: 12px;
    color: #a9a9a9;
  }
  .footerSocialLinks ul li {
    font-size: 24px;
  }
  .aboutDoubleText {
    padding-left: 15px;
    padding-right: 15px;
  }
  .alternateDouble .adlContent {
    padding-left: 15px;
    padding-right: 15px;
  }
}
